import { gql } from '@apollo/client'

export const deactivateInfluencerGiftingCoupon = gql`
  mutation deactivateInfluencerGiftingCoupon(
    $influencerIds: [String]!
    $couponIds: [String]!
  ) {
    deactivateInfluencerGiftingCoupon(
      influencerIds: $influencerIds
      couponIds: $couponIds
    ){
        message
        code
        success
      }
  }
 `
